<template>
  <div>
    <v-snackbar v-model="showsnackbar" color="#3F033B" right  :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 md12>
        <v-card class="px-4 py-2 rounded-xl" elevation="0" color="#e7e2ff" >
        <v-layout wrap>
          <v-flex >
            <v-layout wrap>
              <v-flex xs1 align-self-center>
                <v-img
                  src="../../src/assets/icons/coin 2.png"
                  height="15"
                  width="15"
                >
                </v-img>
              </v-flex>
              <v-flex xs10 pl-1 text-left class="heed"> GOLD : ₹ {{view.goldRate}} 
                
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex >
            <v-layout wrap>
              <v-flex xs1>
                <v-img
                  src="../../src/assets/icons/coin (2) 2.png"
                  height="15"
                  width="15"
                >
                </v-img>
              </v-flex>
              <v-flex xs10 pl-1 text-left class="heed"> SILVER : ₹ {{view.silverRate}} </v-flex>
            </v-layout>
          </v-flex>
          <v-flex >
            <v-layout wrap>
              <v-flex xs1>
                <v-img
                  src="../../src/assets/icons/gold-ingot 2.png"
                  height="15"
                  width="15"
                >
                </v-img>
              </v-flex>
              <v-flex xs10 pl-1 text-left class="heed">THANKAM : ₹ {{view.thankamRate}} </v-flex>
            </v-layout>
          </v-flex>
          <v-flex >
            <v-layout wrap>
              <v-flex xs1 align-self-center>
                <v-img
                  src="../../src/assets/icons/diamond 2.png"
                  height="15"
                  width="15"
                >
                </v-img>
              </v-flex>
              <v-flex xs10 pl-1 text-left class="heed"> DIAMOND : ₹ {{view.diamond}} </v-flex>
            </v-layout>
          </v-flex>
          <v-flex >
            <v-layout wrap>
              <v-flex xs1 align-self-center>
                <v-img
                  src="../../src/assets/icons/platinum (1) 2.png"
                  height="15"
                  width="17"
                >
                </v-img>
              </v-flex>
              <v-flex xs10 pl-1  text-left class="heed"> PLATINUM : ₹ {{view.platinumRate}} </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from "./../store";
export default {
  data() {
    return {
      appLoading: false,
      view: [],
      ServerError: false,
      showsnackbar: false,
      timeout: 3000,
      msg: null,
      billDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  
  beforeMount() {
    // if(this.billDate){
      this.getBording();
    // }
  },
  computed: {
    appLogin() {
      return this.$store.state.isLoggedIn;
    },
    appUser() {
      return this.$store.state.email;
    },
    userData() {
      return this.$store.state.userData;
    },
  },
  methods: {
  
    getBording() {
      // console.log("4");
      this.appLoading = true;
      axios({
        url: "/rate/view",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          date: this.billDate,
        },
      })
        .then((response) => {
          if (response.data.status == true){
          this.appLoading = false;
          this.view = response.data.data;
          store.commit("headData", response.data.data);
          localStorage.setItem("diamond", response.data.data.diamond);
          localStorage.setItem("goldRate", response.data.data.goldRate);
          localStorage.setItem("platinumRate", response.data.data.platinumRate);
          localStorage.setItem("silverRate", response.data.data.silverRate);
          localStorage.setItem("thankamRate", response.data.data.thankamRate);
        }
        // else{
            // console.log("false");
            // this.msg = response.data.msg;
            // this.showsnackbar = true;
          // }

        })
        .catch((err) => {
          //   this.ServerError = true;
          console.log(err);
        });
    },
   
  },
};
</script>
<style scoped>
.heed {
  font-family: DmSansBold;
  font-size: 14px;
}
</style>