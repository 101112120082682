<template>
  <div id="appNavbar">
   <v-layout wrap justify-end class="hidden-md-and-down">
    <!-- :color="newGSTstatus==true?'white':'#efefef'" -->
     
      <v-flex lg12 align-self-center>
        <v-app-bar
        :color="newGSTstatus==true?'white':'white'"
          elevation="0"
          height="60px"
          class="hidden-md-and-down"
        >
          <v-layout wrap justify-center fill-height>
            <!-- <v-flex xs2 align-self-center>
            </v-flex> -->
            <v-spacer></v-spacer>
            
            <!-- <v-flex xs6 lg1 xl1 px-4 text-center align-self-center style="cursor:pointer" v-if="appUser.positionid">
                <div v-if="appUser.positionid.length>1">
                    <SwitchAccount />
                </div>
               
            </v-flex> -->
            <v-flex xs12 lg10 xl11 text-left px-4 align-self-center>
              <Price/> 
            </v-flex>
            <v-flex xs6 lg2 xl1 pl-4 text-right align-self-center style="cursor:pointer">
              <AccountMenu />
            </v-flex>
          </v-layout>
        </v-app-bar>
      </v-flex>
    </v-layout>
    <v-layout wrap class="hidden-lg-and-up">
      <v-flex xs12>
        <v-app-bar
      app
      color="white"
      dense
      flat 
      height="50px"
      class="hidden-lg-and-up"
    >
    <v-layout wrap>
      <v-flex xs2>
        <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
        <v-icon color="#3F033B">mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      </v-flex>
      <v-flex xs8 align-self-center text-left pl-2>
        <router-link to="/">
            <span
                  style="
                    font-family: GillSansUltraBold;
                    color: #3F033B;
                    font-size: 28px;
                  "
                  >JewelBIZ</span
                >
              </router-link>
            </v-flex>
            <v-flex xs2 xl1 pl-4 text-right align-self-center style="cursor:pointer">
              <AccountMenu />
            </v-flex>
    </v-layout>
     
      <!-- <v-toolbar-title>
        <router-link to="/">
          <v-layout wrap justify-center>
            <v-flex xs10 align-self-center text-left>
            <span
                  style="
                    font-family: GillSansUltraBold;
                    color: #3F033B;
                    font-size: 28px;
                  "
                  >JewelBIZ</span
                >
            </v-flex>
            <v-flex xs2 xl1 pl-4 text-right align-self-center style="cursor:pointer">
              <AccountMenu />
            </v-flex>
          </v-layout>
        </router-link>
      </v-toolbar-title> -->
    </v-app-bar>
  </v-flex>
    <v-navigation-drawer v-model="sideNav" fixed temporary  src=".././assets/images/NavBar.png"
          overlay-color="white"  color="#3F033B">
          <v-flex xs12 py-6 align-self-center>
            <!-- <v-card tile height="70px" color="transparent"> -->
            <router-link to="/">
              <span style="
                      font-family: GillSansUltraBold;
                      color: white;
                      font-size: 25px;
                    ">JewelBIZ</span>
            </router-link>
            <!-- </v-card> -->
          </v-flex>
          <!---------------------------------- SuperAdmin sidebar-------------------------------------------->
          <v-layout wrap>
            <v-flex xs12 text-left text-uppercase align-self-center v-if="navItems">
           <v-layout wrap justify-center>
            <v-flex xs6 align-self-center pl-2 pb-3>
              <span v-if="appType == 'Staff'" @click="$router.push('/')" class="subhed text-none" style="color: #ffffff !important; cursor: pointer;">
                             Dashboard
                            </span>
            </v-flex>
           </v-layout>
              <v-list dense v-for="(main, i) in navItems" :key="i" class="subhed">
                <v-layout wrap>
                  <v-flex xs12 py-1>
                    <v-list-group no-action :value="false" active-class="activated">
                      <template v-slot:activator>
                        <v-list-item-icon>
                          <v-img height="20px" contain :src="main.menu.src"></v-img>
                        </v-list-item-icon>
                        <router-link :to="main.menu.route">
                          <!-- <v-list-item-title style="
                                letter-spacing: 2px;
                                color: black;
                                cursor: pointer;
                              ">
                              -->
                            <span class="text-none subhed" style="color: #ffffff !important; cursor: pointer;">
                              {{ main.menu.name }}
                            </span>
                          <!-- </v-list-item-title> -->
                        </router-link>
                      </template>
                      <!-- submenu -->
                      <!-- <v-flex xs12 py-1 px-4>
                        <div style="border-bottom: 1px dotted black"></div>
                      </v-flex> -->
                      <div v-if="main.menu.subMenu" class="subhed">
                        <template v-for="(sub, i) in main.menu.subMenu">
                          <v-flex xs12 text-left pl-6 py-1 :style="
                            $route.path == sub.route
                              ? ''
                              : 'background:rgba(0, 0, 0, 0.1)'
                          " :key="i">
                            <v-layout wrap justify-center>
                              <v-flex xs12>
                                <router-link :to="sub.route">
                                  <span style="letter-spacing: 3px; cursor: pointer">
                                    <span class="text-none" :style="
                                      $route.path == sub.route
                                        ? 'color:#ffffff'
                                        : 'color: #ffffff'
                                    ">
                                      {{ sub.name }}
                                    </span>
                                  </span>
                                </router-link>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <!-- <v-flex xs12 py-1 px-4 pl-6 :key="sub._d">
                          <div style="border-bottom: 1px solid grey"></div>
                        </v-flex> -->
                        </template>
                      </div>
                      <!-------------->
                    </v-list-group>
                  </v-flex>
                </v-layout>
              </v-list>
            </v-flex>
            <!-- <v-flex xs12 text-left py-2 text-uppercase align-self-center v-if="navItems && this.appType == 'Staff'"><span
                style="color:white;"></span>
              <v-list dense v-for="(main, i) in navItems" :key="i" class="subhed">


                <v-layout wrap v-if="main">
                  <v-flex xs12 py-2>
                    <v-list-group no-action :value="false" active-class="activated">
                      <template v-slot:activator>
                        <v-list-item-title style="
                                letter-spacing: 2px;
                                color: black;
                                cursor: pointer;
                              ">
                          <router-link :to="main.menu.route">
                          <span class="text-none" style="color: #ffffff !important">
                            {{ main.menu.name }}
                          </span>
                          </router-link>
                        </v-list-item-title>
                      </template>
                    </v-list-group>
                  </v-flex>
                </v-layout>
              </v-list>
            </v-flex> -->
          </v-layout>
        </v-navigation-drawer>
<!-- xs header -->
    
    </v-layout>
  </div>
</template>
<script>
import AccountMenu from "./../components/accountMenu";
import Price from "./../components/PricePage.vue";
// import SwitchAccount from "./../components/switchAccount";
export default {
  components: {
    AccountMenu,
    Price,
    // SwitchAccount
  },
  data() {
    return {
      sideNav: false,
      admin: [{ name: "ADMINS", route: "/Admins" }],
      menu2: [],
      userNavItemsSuperAdmin: [
        {
          menu: {
            name: "Add Admin",
            route: "/dashboard",
          },
        },
        {
          menu: {
            name: "Admin List",
            route: "/ViewAdmin",
          },
        },
        {
          menu: {
            name: "Menu Master",
            route: "/MenuMaster",
          },
        },
      ],

      userNavItemsJuwelAdmin: [
        {
          menu: {
            name: "DASHBOARD",
            route: "/JewelleryDashboard",
            src: require("../assets/icons/Home.png"),
          },
        },
        {
          menu: {
            name: "CATEGORY",
            route: "/Category",
            src: require("../assets/icons/Filter.png"),

          },
        },
        {
          menu: {
            name: "GOLD SCHME",
            route: "/JewelleryDashboard",
            src: require("../assets/icons/Bag.png"),

          },
        },
        {
          menu: {
            name: "STAFF",
            route: "/staff",
            src: require("../assets/icons/2User.png"),
          },
        },
        {
          menu: {
            name: "SUPPLIER",
            route: "/Supplier",
            src: require("../assets/icons/Profile.png"),
          },
        },
        {
          menu: {
            name: "CUSTOMER",
            route: "/Customer",
            src: require("../assets/icons/3User.png"),
          },
        },
        {
          menu: {
            name: "STOCK",
            route: "/StockPage",
            src: require("../assets/icons/Swap.png"),
          },
        },
        {
          menu: {
            name: "BOARDING RATE",
            route: "/BoardingRate",
            src: require("../assets/icons/moneys.png"),
          },
        },
        {
          menu: {
            name: "PURCHASE",
            route: "/PurchaseList",
            src: require("../assets/icons/Buy.png") ,
          },
        },
        {
          menu: {
            name: "OLD GOLD",
            route: "/JewelleryDashboard",
            src: require("../assets/icons/glass.png"),
          },
        },
        {
          menu: {
            name: "PROFIT & LOSS",
            route: "/JewelleryDashboard",
            src: require("../assets/icons/Graph.png"),
          },
        },
        {
          menu: {
            name: "REPORT",
            route: "/JewelleryDashboard",
            src: require("../assets/icons/Paper.png"),
            subMenu: [
              {
                name: "STOCK REPORT",
                route: "/JewelleryDashboard",
                src: require("../assets/icons/2User.png"),
              },
              {
                name: "PURCHASE REPORT",
                route: "/JewelleryDashboard",
                src: require("../assets/icons/2User.png"),
              },
              {
                name: "SALES ITEM REPORT",
                route: "/JewelleryDashboard",
                src: require("../assets/icons/2User.png"),
              },
              {
                name: "CUSTOMER WISE REPORT",
                route: "/JewelleryDashboard",
                src: require("../assets/icons/2User.png"),
              },
            ],
          },
        },
        {
          menu: {
            name: "SETTINGS",
            route: "/MenuManagement",
            src: require("../assets/icons/Setting.png"),
          },
        },
      ],
      userNavItemsStaffAdmin: [],
      newGSTstatus:"",
    };
  },
  beforeMount() {
    this.userNavItemsStaffAdmin = localStorage.getItem("menu");

    // console.log("menu", this.userNavItemsStaffAdmin);

  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
    appType() {
      return this.$store.state.userType;
    },
    userNavItemsStaffAdmin1(){
      return this.$store.state.menu;

    },
    navItems() {
      if (this.appType == "SuperAdmin") return this.userNavItemsSuperAdmin;
      else if (this.appType == "JewelleryAdmin")
        return this.userNavItemsJuwelAdmin;
      else if (this.appType == "Staff") return this.userNavItemsStaffAdmin1;
      else return this.userNavItems;
    },
    appMenu() {
      // console.log("menus", store.state.menus);
      return this.$store.state.menus;
    },
    appLogin() {
      return false;
    },
    // appType() {
    //   return this.$store.state.userType;
    // },
    appNavItems() {
      return this.$store.state.menus;
    },
    GSTstatus(){
      // return this.$store.state.GSTstatus;
      return localStorage.getItem("GSTstatus")
    },
    
  },
   watch:{
    GSTstatus(){
      this.newGSTstatus=JSON.parse(this.GSTstatus);
      console.log("newGSTstatus==",this.newGSTstatus)
    }
  },
};
</script>

<style>
input::-webkit-input-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: kumbhRegular !important;
}
input::-moz-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: kumbhRegular !important;
}
.searchBox .v-input__control {
  min-height: 10px !important;
}
.searchBox .v-input__control .v-input__slot {
  padding: 0px !important;
}
.v-list-group__header__append-icon {
  display: none !important;
}

@media only screen and (min-device-width: 767px) {
  .fixedSidebar {
    /* position: fixed; */

    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 50;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}

/* .activated { */
.activated {
  color: rgba(63, 3, 59, 0.9) !important;
}

.deactivated {
  color: rgba(63, 3, 59, 0.9) !important;
}
</style>
