<template>
  <div>
    <v-layout wrap justify-end>
      <v-flex xs12 md12>
        <v-menu offset-y right>
          <template v-slot:activator="{ attrs, on }">
            <!-- <v-layout wrap justify-center v-bind="attrs" v-on="on" class="hidden-lg-and-up" >
              <v-flex xs12  align-self-center>
                <v-icon large>mdi-account-circle-outline</v-icon>
              </v-flex>
          </v-layout> -->
            <v-layout wrap justify-center v-bind="attrs" v-on="on"  class="hidden-md-and-down">
              <v-flex xs2  align-self-center>
                <!-- <v-icon large title="Account Info" size="20">
                  mdi-account-star
                </v-icon> -->
                <!-- <v-img src="../../src/assets/icons/settings.png"></v-img> -->
                <v-icon large :color="GSTstatus==true?'#3F053C':'grey'">mdi-account-circle-outline</v-icon>
              </v-flex>
              <v-flex xs10 text-left pl-4>
               <v-layout wrap>
                <v-flex xs12>
                  <span style="font-family:DmSansMedium;font:size:16px;color:black; ">
                  {{ userData.username }}
                  <!-- {{ userData.username.split(' ')[0] }} -->

                </span>
                  </v-flex>
                  <v-flex xs12>
                <span
                  style="
                    font-family: DmSansRegular;
                    font-size: 14px;
                    color: grey;
                  "
                >
                  {{ userData.mainRole }}</span
                >
                </v-flex>
               </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center v-bind="attrs" v-on="on"  class="hidden-lg-and-up">
              <v-flex xs12  align-self-center>
                <v-icon  :color="GSTstatus==true?'#3F053C':'grey'">mdi-account-circle-outline</v-icon>
              </v-flex>
            </v-layout>
          </template>
          <v-card tile flat :elevation="0" max-width="250px">
            <v-layout wrap justify-center py-4>
              <v-flex pt-4 xs12>
                <span
                  style="
                    font-family: kumbhEBold;
                    font-size: 16px;
                    color: #000000;
                  " 
                >
                  Welcome<br />
                  {{ userData.username }} <br />
                </span>
                </v-flex>
              <v-flex pt-1 xs12 v-if="userData.mainRole=='JewelleryAdmin'">

                  <span v-if="userData.jewelleryName"
                  style="
                    font-family: kumbhSemibold;
                    font-size: 14px;
                    color: #000000;
                  " 
                >
                  {{ userData.jewelleryName }}
                </span>
              </v-flex>
              <v-flex pt-1 xs12 v-if="userData.mainRole=='Staff' && userData.subRoleId">
                <span v-if="userData.subRoleId.jewelleryId"
                  style="
                    font-family: kumbhSemibold;
                    font-size: 14px;
                    color: #000000;
                  " 
                >
                  {{ userData.subRoleId.jewelleryId.jewelleryName }}
                  <!-- {{ userData.subRoleId.jewelleryId.jewelleryName.split(' ')[0] }} -->
                </span>
              </v-flex>
              <v-flex pt-2 xs12>
                <v-icon  :color="taxType == true ? '#CCB4B4' : 'grey'" size="30">
                  mdi-account-circle-outline
                </v-icon>
              </v-flex>
              <v-flex xs12 v-if="userData.mainRole=='JewelleryAdmin'">
                <router-link to="/profile">
                  <span
                    v-if="appLogin"
                    style="
                      font-family: poppinsmedium;
                      font-size: 13px;
                      color: #1e1b1b;
                    "
                  >
                    <!-- <span v-if="appUser.guest">
                      {{ appUser.guest.name }}
                    </span> -->
                  </span>
                </router-link>
              </v-flex>
              <v-flex xs12 pt-2>
                <v-btn
                  v-if="appLogin"
                  color="#0000001f"
                  outlined
                  tile
                  small
                  :ripple="false"
                  @click="$router.push('/userProfile')"
                >
                  <span
                    style="
                      font-family: kumbhEBold;
                      font-size: 14px;
                      color: #3f033b;
                    "
                  >
                  Profile
                  </span>
                </v-btn>
              </v-flex>
              <v-flex xs12 pt-2>
                <v-btn
                  v-if="!appLogin"
                  color="#0000001f"
                  outlined
                  tile
                  small
                  :ripple="false"
                  to="/login"
                >
                  <span
                    style="
                      font-family: kumbhEBold;
                      font-size: 14px;
                      color: #000000;
                    "
                  >
                    LOGIN
                  </span>
                </v-btn>
                <v-btn
                  v-if="appLogin"
                  color="#0000001f"
                  outlined
                  tile
                  small
                  :ripple="false"
                  @click="appLogout"
                >
                  <span
                    style="
                      font-family: kumbhEBold;
                      font-size: 14px;
                      color: #3f033b;
                    "
                  >
                    LOGOUT
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-menu>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import store from "./../store";
import axios from "axios";
export default {
  data() {
    return {
      user: {},
      taxType:localStorage.getItem("GSTstatus")
    };
  },
  computed: {
    appLogin() {
      return this.$store.state.isLoggedIn;
    },
    appUser() {
      return this.$store.state.email;
    },
    userData() {
      return this.$store.state.userData;
    },
    GSTstatus(){
      return this.$store.state.GSTstatus;
    },
  },

  methods: {
    appLogout() {
      axios({
        method: "get",
        url: "/user/logout",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status==true) {
            this.$store.commit("logoutUser", true);
            localStorage.removeItem("id");
            // store.commit("menu", response.data.menus);
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script> 
