<template>
  <div>
    <!-- <v-divider></v-divider> -->
    <v-layout wrap justify-center fill-height>
      <v-flex xs12 sm12 md12 lg2 hidden-md-and-down>
        <v-navigation-drawer style="z-index:2000;" class="fixedSidebar" v-model="sideNav" clipped dark src=".././assets/images/NavBar.png"
          overlay-color="white" width="100%" height="100vh" permanent color="#3F033B">
          <v-flex xs12 py-6 align-self-center>
            <!-- <v-card tile height="70px" color="transparent"> -->
            <router-link to="/">
              <span style="
                      font-family: GillSansUltraBold;
                      color: white;
                      font-size: 28px;
                    ">JewelBIZ</span>
                    <span v-if="GSTstatus=='false'" style="
                     
                      color: white;
                      font-size: 10px;
                    ">Demo</span>
            </router-link>
            <!-- </v-card> -->
          </v-flex>
          <!---------------------------------- SuperAdmin sidebar-------------------------------------------->
          <v-layout wrap>
            <v-flex xs12 text-left text-uppercase align-self-center v-if="navItems && this.appType != 'Staff'">
              <v-list dense v-for="(main, i) in navItems" :key="i" class="subhed">
                <v-layout wrap>
                  <v-flex xs12 py-1>
                    <router-link :to="main.menu.route">
                    <v-list-group no-action :value="false" active-class="activated">
                      <template v-slot:activator>
                        <v-list-item-icon>
                          <v-img height="20px" contain :src="main.menu.src"></v-img>
                        </v-list-item-icon>
                        <!-- <router-link :to="main.menu.route"> -->
                          <v-list-item-title style="
                                letter-spacing: 2px;
                                color: black;
                                cursor: pointer;
                              ">
                              
                            <span class="text-none" style="color: #ffffff !important">
                              {{ main.menu.name }}
                            </span>
                          </v-list-item-title>
                        <!-- </router-link> -->
                      </template>
                      <!-- submenu -->
                      <!-- <v-flex xs12 py-1 px-4>
                        <div style="border-bottom: 1px dotted black"></div>
                      </v-flex> -->
                      <div v-if="main.menu.subMenu" class="subhed">
                        <template v-for="(sub, i) in main.menu.subMenu">
                          <v-flex xs12 text-left pl-6 py-1 :style="
                            $route.path == sub.route
                              ? ''
                              : 'background:rgba(0, 0, 0, 0.1)'
                          " :key="i">
                            <v-layout wrap justify-center>
                              <v-flex xs12>
                                <router-link :to="sub.route">
                                  <span style="letter-spacing: 3px; cursor: pointer">
                                    <span class="text-none" :style="
                                      $route.path == sub.route
                                        ? 'color:#ffffff'
                                        : 'color: #ffffff'
                                    " style="
                                letter-spacing: 2px;
                                font-size: 13px !important;
                                cursor: pointer;
                              ">
                                     -> {{ sub.name }}
                                    </span>
                                  </span>
                                </router-link>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <!-- <v-flex xs12 py-1 px-4 pl-6 :key="sub._d">
                          <div style="border-bottom: 1px solid grey"></div>
                        </v-flex> -->
                        </template>
                      </div>
                      <!-------------->
                    </v-list-group>
                    </router-link>
                  </v-flex>
                </v-layout>
              </v-list>
            </v-flex>
            <v-flex xs12 text-left py-2 text-uppercase align-self-center v-if="navItems && this.appType == 'Staff'"><span
                style="color:white;"></span>
                <v-layout wrap>
            <v-flex xs6 align-self-center pl-4 pb-3>
              <span v-if="appType == 'Staff'" @click="$router.push('/')" class="subhed text-none" style="color: #ffffff !important; cursor: pointer;">
                             DASHBOARD
                            </span>
            </v-flex>
           </v-layout>
              <v-list dense v-for="(main, i) in navItems" :key="i" class="subhed">


                <v-layout wrap v-if="main">
                  <v-flex xs12 py-2>
                    <router-link :to="main.menu.route">
                    <v-list-group no-action :value="false" active-class="activated">
                      <template v-slot:activator>
                        <!-- <v-list-item-title style="
                                letter-spacing: 2px;
                                color: black;
                                cursor: pointer;
                              "> -->
                          <!-- <router-link :to="main.menu.route"> -->
                          <span class="text-none subhed" style="color: #ffffff!important; text-transform: uppercase !important;  cursor: pointer;">
                            {{ main.menu.name }}
                          </span>
                          <!-- </router-link> -->
                        <!-- </v-list-item-title> -->
                      </template>
                      <!-- <div v-if="main.submenus" class="subhed">
                        <template v-for="(sub, i) in main.submenus">
                          <v-flex xs12 text-left pl-6 py-1 :style="
                            $route.path == sub.route
                              ? ''
                              : 'background:rgba(0, 0, 0, 0.1)'
                          " :key="i">
                            <v-layout wrap justify-center>
                              <v-flex xs12>
                                <router-link :to="sub.route">
                                  <span style="letter-spacing: 3px; cursor: pointer">
                                    <span class="text-none" :style="
                                      $route.path == sub.route
                                        ? 'color:#ffffff'
                                        : 'color: #ffffff'
                                    ">
                                      {{ sub.name }}
                                    </span>
                                  </span>
                                </router-link>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </template>
                      </div> -->
                      <!-------------->
                    </v-list-group>
                    </router-link>
                  </v-flex>
                </v-layout>
              </v-list>
            </v-flex>
          </v-layout>
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 md12 lg10 pt-12 pt-lg-0>
        <AppHeader />
        <!-- to set color for dashboard bg according to user -->
        <!-- <v-layout
          wrap
          justify-center
          :style="
            $route.name == 'dashboard'
              ? 'background-color: #f5daf5'
              : 'background-color: #f5daf5'
          "
        > -->
        <v-layout wrap justify-center style="background-color: #f9f6ff; min-height: 100vh">
          <v-flex xs12 pa-6>
            <router-view :key="$route.fullPath"></router-view>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import AppHeader from "./../layout/AppHeader";
export default {
  components: {
    AppHeader,
  },

  data() {
    return {
      sideNav: true,
      admin: [{ name: "ADMINS", route: "/Admins" }],
      menu2: [],
      // GSTstatus:localStorage.getItem("GSTstatus"),
      userNavItemsSuperAdmin: [
        {
          menu: {
            name: "Add Admin",
            route: "/superadminDashboard",
          },
        },
        {
          menu: {
            name: "Admin List",
            route: "/ViewAdmin",
          },
        },
      ],

      userNavItemsJuwelAdmin: [
        {
          menu: {
            name: "DASHBOARD",
            route: "/StaffDashboard",
            src: require("../assets/icons/Home.png"),
          },
        },
        {
          menu: {
            name: "PURCHASE",
            route: "/PurchaseList",
            src: require("../assets/icons/Buy.png") ,
          },
        },
        {
          menu: {
            name: "ESTIMATE",
            route: "/EstimatePage",
            src: require("../assets/icons/statusup.png"),
          },
        },
        {
          menu: {
            name: "BILLING",
            route: "/SalesList",
            src: require("../assets/icons/moneysend.png"),
          },
        },
        {
          menu: {
            name: "STOCK",
            route: "/StockPage",
            src: require("../assets/icons/Swap.png"),
          },
        },
        {
          menu: {
            name: "SUPPLIER",
            route: "/Supplier",
            src: require("../assets/icons/Profile.png"),
          },
        },
        {
          menu: {
            name: "CATEGORY",
            route: "/Category",
            src: require("../assets/icons/Filter.png"),

          },
        },
        {
          menu: {
            name: "HSN",
            route: "/hsnCode",
            src: require("../assets/icons/Edit.png"),

          },
        },
        {
          menu: {
            name: "GOLD SCHEME",
            route: "/GoldScheme",
            src: require("../assets/icons/Bag.png"),

          },
        },
        {
          menu: {
            name: "SCHEME LIST",
            route: "/CustomerSchemeList",
            src: require("../assets/icons/Bookmark.png"),

          },
        },
        {
          menu: {
            name: "STAFF",
            route: "/staff",
            src: require("../assets/icons/2User.png"),
          },
        },
       
        {
          menu: {
            name: "CUSTOMER",
            route: "/Customer",
            src: require("../assets/icons/3User.png"),
          },
        },
       
       
        {
          menu: {
            name: "SALES HISTORY",
            route: "/SalesPage1",
            src: require("../assets/icons/Paper.png"),
          },
        },
       
        {
          menu: {
            name: "BOARDING RATE",
            route: "/BoardingRate",
            src: require("../assets/icons/moneys.png"),
          },
        },
        
        {
          menu: {
            name: "OLD GOLD",
            route: "/AcidingList",
            src: require("../assets/icons/glass.png"),
          },
        },
        {
          menu: {
            name: "PROFIT & LOSS",
            route: "/ProfitandLoss",
            src: require("../assets/icons/Graph.png"),
          },
        },
        {
          menu: {
            name: "REPORT",
            route: "",
            src: require("../assets/icons/Paper.png"),
            subMenu: [
              {
                name: "STOCK REPORT",
                route: "/StockPage",
                src: require("../assets/icons/2User.png"),
              },
              {
                name: "PURCHASE REPORT",
                route: "/PurchaseList",
                src: require("../assets/icons/2User.png"),
              },
              {
                name: "SALES ITEM REPORT",
                route: "/salesReport",
                src: require("../assets/icons/2User.png"),
              },
              {
                name: "SALES REPORT",
                route: "/SalesPage1",
                src: require("../assets/icons/2User.png"),
              },
              {
                name: "OLD GOLD REPORT",
                route: "/oldGoldList",
                src: require("../assets/icons/2User.png"),
              },
              {
                name: "ACIDING REPORT",
                route: "/AcidingReport",
                src: require("../assets/icons/2User.png"),
              },
              // {
              //   name: "RETURN ITEM REPORT",
              //   route: "/returnGoldList",
              //   src: require("../assets/icons/2User.png"),
              // },
            ],
          },
        },
        {
          menu: {
            name: "SETTINGS",
            route: "/MenuManagement",
            src: require("../assets/icons/Setting.png"),
          },
        },
      ],
      userNavItemsStaffAdmin: [],
    };
  },
  computed: {
    appType() {
      return this.$store.state.userType;
    },
    GSTstatus(){
    return localStorage.getItem("GSTstatus");

    },
    userNavItemsStaffAdmin1(){
      return this.$store.state.menu;

    },
    // appMenu() {
    //   console.log("menus", store.state.menus);

    //   if (this.$store.state.menus.length > 0 && localStorage.getItem("positionId") != null) {
    //     return this.$store.state.menus;
    //   } else {
    //     return this.NewEmpMenu;
    //   }

    // },
    navItems() {
      if (this.appType == "SuperAdmin") return this.userNavItemsSuperAdmin;
      else if (this.appType == "JewelleryAdmin")
        return this.userNavItemsJuwelAdmin;
      else if (this.appType == "Staff") return this.userNavItemsStaffAdmin1;
      else return this.userNavItems;
    },
  },
  // beforeMount() {
  //   this.getMenu();
  // },
  // beforeMount() {
  //   this.userNavItemsStaffAdmin = localStorage.getItem("menu");

  //   console.log("menu", this.userNavItemsStaffAdmin);
  // },
  // watch:{
  //   userNavItemsStaffAdmin1(){
  //     this.userNavItemsStaffAdmin=this.userNavItemsStaffAdmin1;
  //     console.log("userNavItemsStaffAdmin==",this.userNavItemsStaffAdmin)
  //   }
  // },
  methods: {
    //      getMenu() {
    //  this.userNavItemsStaffAdmin=this.localStorage.getItem("menu")
    //  console.log("menu",this.userNavItemsStaffAdmin)
    // },
  },
};
</script>
<style>
.v-list-group__header__append-icon {
  display: none !important;
}

@media only screen and (min-device-width: 767px) {
  .fixedSidebar {
    /* position: fixed; */

    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 50;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}

/* .activated { */
.activated {
  color: rgba(63, 3, 59, 0.9) !important;
}

.deactivated {
  color: rgba(63, 3, 59, 0.9) !important;
}

/* } */
</style>
